import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getMarkdownNodeBySlug, getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";
import { AboutContainer, HeroContainer, PortfolioContainer, ServiceContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexScubaDiverQuery {
      allMarkdown: allMarkdownRemark(
        filter: {frontmatter: {scope: {eq: "index-scuba-diver"}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              categories
              date(formatString: "LLL")
              scope
              slug
              title
            }
            html
          }
        }
      }

      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "index-scuba-diver"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
`);

  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "service") && (
            <ServiceContainer
              data={getPageItemByScope(data.page?.items, "service")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "about-me") && (
            <AboutContainer
              id="about-me"
              data={{
                item: getPageItemByScope(data.page?.items, "about-me"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "about-me")
              }}
            />
          )}

          {hasPageItemByScope(data.page?.items, "diving-stimulates-art-art-stimulates-diving") && (
            <PortfolioContainer
              id="diving-stimulates-art-art-stimulates-diving"
              data={{
                item: getPageItemByScope(data.page?.items, "diving-stimulates-art-art-stimulates-diving"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "diving-stimulates-art-art-stimulates-diving")
              }}
            />
          )}

          {hasPageItemByScope(data.page?.items, "dive-with-your-kids") && (
            <PortfolioContainer
              id="dive-with-your-kids"
              data={{
                allFile: data.allFile,
                item: getPageItemByScope(data.page?.items, "dive-with-your-kids"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "dive-with-your-kids")
              }}
            />
          )}
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default IndexPage;
